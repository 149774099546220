import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { PlayBtn, View, TimeClock } from 'ion-icon'
import { ImageOrNoImage } from 'ion-image'
// import { emitter } from '@marvelapp/react-ab-test'

function kFormatter (num) {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}

class ArticleCard extends Component {
  render () {
    const article = this.props.article
    const showAbstract = this.props.showAbstract
    const showPlayBtn = this.props.showPlayBtn
    const noImage = this.props.noImage
    const width = this.props.width
    const withMinRead = this.props.withMinRead
    const readTime = withMinRead ? ' | ' + (article.wordCount / 150).toFixed(0) + ' min read' : ''
    return (
      <article key={article.contentKey} aria-label='article'>
        <Link to={'/' + article.getCanonicalUri()}>
          {(showPlayBtn && <div className='play-btn'><PlayBtn width='48' height='48' /></div>)}
          <ImageOrNoImage image={article.image} width={width} alt={article.headline} shape='16x9' noImage={noImage} />
        </Link>
        <div className='content'>
          <small className='meta'>
            <TimeClock width='12' height='12' /> {article.formatAgo(false)} | <Link className='category' to={'/' + article.getSectionId()}>{article.sectionLabel}</Link> {readTime}
            {(this.props.withReadCount && (article.readCount > 200))
              ? (
                <span className='views'><View width='12' height='12' />{kFormatter(article.readCount)}</span>
                )
              : ''}
          </small>
          <Link to={'/' + article.getCanonicalUri()}>
            <h3>{article.headline}</h3>
            {(showAbstract && <p>{article.getAbstract()}</p>)}
          </Link>
          {(article.sponsoredIndicator === 'SPONSORED' || article.sponsoredIndicator === 'SPONSORED_EXPIRED') &&
            <small className='sponsored'>Sponsored</small>}
          {(article.sponsoredIndicator === 'NATIVE' || article.sponsoredIndicator === 'NATIVE_EXPIRED') &&
            <small className='native'>Partnered</small>}
        </div>
      </article>
    )
  }
}

export default ArticleCard
